<template>
  <v-card :loading="loading" v-bind="$attrs" v-on="$listeners">
    <v-toolbar flat>
      <v-toolbar-title class="headline"
        >Sales Activity
        <span> (Last {{ selectedDays }} Days)</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn class="mr-2" small @click="getData(7)" :loading="loading" :disabled="selectedDays == 7">
        Last 7 days
      </v-btn>
      <v-btn class="mr-2" small @click="getData(30)" :loading="loading" :disabled="selectedDays == 30">
        Last 30 days
      </v-btn>
      <v-btn class="mr-2" small @click="getData(60)" :loading="loading" :disabled="selectedDays == 60">
        Last 60 days
      </v-btn>
      <v-btn class="mr-2" small @click="getData(90)" :loading="loading" :disabled="selectedDays == 90">
        Last 90 days
      </v-btn>
    </v-toolbar>
    <v-card-text v-if="!loading">
      <apexchart :height="height ? height : ''" :options="options" :series="series"></apexchart>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment-timezone";
import { mapGetters } from "vuex";

export default {
  name: "SalesPayoutCountAndAmountByDateChart",
  components: {},
  props: {
    height: { type: Number, default: 400 }
  },
  data() {
    return {
      loading: false,
      selectedDays: 7,

      options: {
        chart: {
          type: "line",
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function(value, series) {
            if (series.seriesIndex == 0) {
              let number = parseFloat(value);
              if (isNaN(number)) {
                return value;
              }
              var formatter = new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD"
              });
              return formatter.format(number);
            } else {
              return value;
            }
          }
        },
        plotOptions: {
          bar: {
            dataLabels: {
              position: "top"
            }
          }
        },
        xaxis: {
          // tickPlacement: "between",
          type: "datetime",
          labels: {
            showDuplicates: false,
            format: "MM/dd"
          }
        },
        yaxis: [
          {
            forceNiceScale: true,
            labels: {
              formatter: function(value) {
                let number = parseFloat(value);
                if (isNaN(number)) {
                  return value;
                }
                var formatter = new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD"
                });
                return formatter.format(number);
              }
            }
          },
          {
            forceNiceScale: true,
            opposite: true
          }
        ]
      },
      series: []
    };
  },
  methods: {
    formatBarLabel(value) {
      if (!value || value == "0" || value == 0 || this.selectedDays > 30) {
        return "";
      } else {
        return value.toLocaleString("en-US", { style: "currency", currency: "USD" });
      }
    },
    getData(days) {
      this.loading = true;

      if (!days) {
        days = 30;
      }
      this.selectedDays = days;

      let dateMoment = moment().subtract(days, "days");

      //let epoch = dateMoment.valueOf();
      //let epochToday = moment().valueOf();

      let dateValue = dateMoment.format("YYYY-MM-DDTHH:mm:ss.SSSZ");

      //this.options.xaxis.min = epoch;
      //this.options.xaxis.max = epochToday;

      this.$api
        .post("/api/sales/search/bySalePayoutCountsByDate", {
          isEffective: "Active",
          participant: { id: this.selectedParticipant.id },
          saleDateAfter: dateValue
        })
        .then(({ data }) => {
          let seriesPayoutAmount = data.map(d => {
            return {
              x: d.date,
              y: d.payoutAmount
            };
          });

          let seriesCount = data.map(d => {
            return {
              x: d.date,
              y: d.saleCount
            };
          });
          this.series = [
            { name: "Amount Sold", type: "column", data: seriesPayoutAmount },
            { name: "Number Sold", type: "line", data: seriesCount }
          ];
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  mounted() {
    this.options.colors = this.$chart.getColors();

    this.getData();
  },
  computed: {
    ...mapGetters(["selectedParticipant"])
  }
};
</script>
