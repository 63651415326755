<template>
  <v-card :loading="loading" v-bind="$attrs" v-on="$listeners">
    <v-card-title class="headline">Active {{ $i18n.translate("Organizations") }}</v-card-title>
    <v-card-subtitle>
      <span
        v-if="
          this.selectedFiscalYear && this.selectedFiscalYear.fiscalYearStart && this.selectedFiscalYear.fiscalYearEnd
        "
        ><b
          >({{ this.selectedFiscalYear.fiscalYearStart | formatDateClient("MM/DD/YYYY", selectedClient) }} -
          {{ this.selectedFiscalYear.fiscalYearEnd | formatDateClient("MM/DD/YYYY", selectedClient) }})</b
        ><br
      /></span>

      Active {{ $i18n.translate("Organizations") }} in the {{ $i18n.translate("Program") }}!<br
    /></v-card-subtitle>
    <v-card-text v-if="!loading">
      <v-data-table hide-default-footer :headers="headers" :items="items" :items-per-page="items.length">
        <template v-slot:item.organizationTypeKey="{ item }">
          <template v-if="$privilege.hasPrivilege('ORGANIZATION_READ')">
            <router-link :to="{ name: 'companyManagement', query: { organizationTypeKey: item.organizationTypeKey } }">
              {{ item.organizationTypeKey }}
            </router-link>
          </template>
          <template v-else>
            {{ item.organizationTypeKey }}
          </template>
        </template>
        <template v-slot:item.count="{ item }">
          {{ item.count | toNumber(0) }}
        </template>
        <template slot="body.append">
          <tr>
            <th colspan="2" class="text-right">Total</th>
            <th class="text-right">{{ sumField("count") | toNumber(0) }}</th>
          </tr>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions style="height: 55px">
      <v-spacer></v-spacer>
      <v-btn v-if="showActions" color="primary" :to="{ name: 'companyManagement', query: { active: 1 } }">Manage</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AdminOrganizationsExpandedWidget",
  props: {
    showActions: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      headers: [
        {
          value: "organizationTypeKey",
          text: "Key",
          sortable: false
        },
        {
          value: "organizationTypeName",
          text: "Type",
          sortable: false
        },
        {
          value: "count",
          text: "Count",
          align: "right",
          sortable: false
        }
      ],
      items: [],
      loading: false
    };
  },
  watch: {
    selectedFiscalYear() {
      this.fetchData();
    },
    selectedProgram() {
      this.fetchData();
    }
  },
  computed: {
    ...mapGetters(["selectedProgram", "selectedClient", "selectedFiscalYear", "selectedParticipant"])
  },
  methods: {
    sumField(key) {
      return this.items.reduce((a, b) => a + (b[key] || 0), 0);
    },
    fetchData() {
      this.loading = true;

      let filters = { isEffective: "Active" };
      let currentFiscalYear = this.$client.getCurrentFiscalYear(this.selectedClient).fiscalYear;
      let isCurrentFiscalYear = this.selectedFiscalYear.fiscalYear == currentFiscalYear;

      if (!isCurrentFiscalYear) {
        filters.createdAfter = this.selectedFiscalYear.fiscalYearStart;
        filters.createdBefore = this.selectedFiscalYear.fiscalYearEnd;
      }

      this.$api
        .post("/api/organizations/search/group/byOrganizationType?size=1", filters)
        .then(({ data }) => {
          this.items = data;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
