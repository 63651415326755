<template>
  <v-container class="pt-0 mt-0">
    <v-row class="mt-0 pt-0">
      <v-col class="mt-0 pt-0">
        <v-alert border="top" colored-border color="primary" elevation="2" class="ma-0">
          <ContentView contentKey="RACER_WELCOME" />
        </v-alert>
      </v-col>
    </v-row>

    <Survey />

    <v-row>
      <v-col cols="12" xs="12" sm="12" md="12" lg="3" xl="3">
        <RacerVehiclesWidget height="100%" />
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="12" lg="5" xl="5">
        <RacerResultsWidget height="100%" />
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
        <AwardVehicleCard
          height="100%"
          class="racer-award-vehicle-card"
          selected
          v-if="reloadableCardAwardVehicle"
          :awardVehicle="reloadableCardAwardVehicle"
          :readOnly="true"
          :showTitle="false"
          :showDescription="false"
          lookupPrimaryBankAccount
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <v-alert border="top" colored-border color="primary" elevation="2" class="ma-0" height="100%">
          <ContentView contentKey="RACER_ADMINISTRATION_INQUIRIES" />
        </v-alert>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <v-alert border="top" colored-border color="primary" elevation="2" class="ma-0" height="100%">
          <ContentView contentKey="CUSTOMER_SERVICE_INQUIRIES" />
        </v-alert>
      </v-col>
    </v-row>

    <v-dialog v-if="transfer.dialog" v-model="transfer.dialog" max-width="500px">
      <v-card>
        <v-card-title class="headline">
          Transfer Request
        </v-card-title>

        <v-card-text>
          You have pending transfer requests to approve. Click below to view and manage all pending transfers.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" :to="{ name: 'profile', query: { tab: 'vehiclesTransfer' } }">
            Go to Pending Transfers
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import RacerVehiclesWidget from "../widget/RacerVehiclesWidget.vue";
import RacerResultsWidget from "../widget/RacerResultsWidget.vue";
import { mapGetters } from "vuex";
import AwardVehicleCard from "../../gapp-components/components/common/awardVehicle/AwardVehicleCard.vue";
import ContentView from "../../gapp-components/components/common/content/ContentView.vue";
import Survey from "../../gapp-components/components/surveys/Survey.vue";

export default {
  name: "RacerDashboard",
  metaInfo: {
    title: "Racer Dashboard"
  },
  components: {
    AwardVehicleCard,
    Survey,
    ContentView,
    RacerResultsWidget,
    RacerVehiclesWidget
  },
  data: () => ({
    loading: false,
    env: null,
    reloadableCardAwardVehicle: null,
    availableAwardVehicles: [],
    transfer: {
      dialog: false
    }
  }),
  created() {
    this.env = process.env;
  },
  mounted() {
    this.$store.dispatch("setBreadcrumb", []);
    this.$content.refreshContent();
    this.loading = true;
    this.getAvailableAwardVehicles()
      .then(() => {
        Promise.all([this.getReloadableCardAwardVehicle(), this.verifyTransferRequested()]);
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    verifyTransferRequested() {
      this.loading = true;
      let filters = {};
      filters.status = { name: "PENDING" };
      filters.transferredFrom = { id: this.selectedParticipant.id };
      this.$api.post("/api/productRegistrationTransfers/search", filters).then(({ data }) => {
        if (data.totalElements > 0) {
          this.transfer.dialog = true;
        }
      });
    },
    getAvailableAwardVehicles() {
      return this.$awardvehicle.getAvailableAwardVehicles(this.selectedParticipant).then(avs => {
        this.availableAwardVehicles = avs;
      });
    },

    getReloadableCardAwardVehicle() {
      for (let i = 0; i < this.availableAwardVehicles.length; i++) {
        let av = this.availableAwardVehicles[i];
        if (av.awardVehicleKey == "RACER_PAYOUTS") {
          this.reloadableCardAwardVehicle = av;
          return Promise.resolve(av);
        }
      }
      return Promise.resolve();
    }
  },

  computed: {
    ...mapGetters(["selectedParticipant"])
  }
};
</script>
