<template>
  <v-card v-bind="$attrs" v-on="$listeners" :loading="loading">
    <v-card-title class="headline">Earned</v-card-title>
    <v-card-subtitle>
      <span
        v-if="
          this.selectedFiscalYear && this.selectedFiscalYear.fiscalYearStart && this.selectedFiscalYear.fiscalYearEnd
        "
        ><b
          >({{ this.selectedFiscalYear.fiscalYearStart | formatDateClient("MM/DD/YYYY", selectedClient) }} -
          {{ this.selectedFiscalYear.fiscalYearEnd | formatDateClient("MM/DD/YYYY", selectedClient) }})</b
        ><br
      /></span>
      Total amount earned in this program!</v-card-subtitle
    >
    <v-card-text class="display-2 text-center" v-if="result != null">
      {{ result | toNumber(2, selectedProgram) }}
      {{ selectedParticipant?.address?.country?.currencyName }}
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ParticipantEarnedWidget",
  data() {
    return {
      loading: false,
      participant: {},
      result: null
    };
  },
  methods: {
    getData() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      let filters = {
        participant: { id: this.selectedParticipant.id },
        isEffective: "Active",
        completelyPaid: true
      };
      if (this.selectedFiscalYear && this.selectedFiscalYear.fiscalYearStart && this.selectedFiscalYear.fiscalYearEnd) {
        filters.createdAfter = this.selectedFiscalYear.fiscalYearStart;
        filters.createdBefore = this.selectedFiscalYear.fiscalYearEnd;
      }

      this.$api
        .post("/api/payouts/search/sumTotalAmount", filters)
        .then(({ data }) => {
          this.result = data;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  watch: {
    selectedFiscalYear() {
      this.getData();
    },
    keyword() {
      this.getData();
    }
  },
  mounted() {
    if (this.result == null) {
      this.getData();
    }
  },
  computed: {
    ...mapGetters(["selectedParticipant", "selectedClient", "selectedProgram", "selectedFiscalYear"])
  }
};
</script>
