<template>
  <v-card :loading="loading" v-bind="$attrs" v-on="$listeners">
    <v-card-title>
      <v-row>
        <v-col>
          <span class="headline">Race Results</span>
        </v-col>
        <v-col class="text-right">
          <v-btn small color="primary" :to="{ name: 'racerResults' }">View All</v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-subtitle>Most recent race results and earnings</v-card-subtitle>
    <v-card-text class="display-2 text-center">
      <v-progress-circular v-if="loading" :size="20" indeterminate color="primary"></v-progress-circular>
      <v-data-table v-else-if="items.length" :items="items" :headers="headers" hide-default-footer>
        <template v-slot:item.effectiveDate="{ item }">
          {{ item.effectiveDate | formatDateClient("MM/DD/YYYY", selectedClient) }}
        </template>
        <template v-slot:item.payoutAmount="{ item }">
          {{ item.payoutAmount | toCurrency }}
        </template>
      </v-data-table>
      <v-alert v-else type="info">Your race results will show up here</v-alert>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "RacerResultsWidget",
  data() {
    return {
      items: [],
      headers: [
        {
          value: "effectiveDate",
          text: "Date",
          sortable: false
        },
        {
          value: "customFieldValue2",
          text: "Series Name",
          sortable: false
        },
        {
          value: "customFieldValue6",
          text: "Position",
          align: "right",
          sortable: false
        },
        {
          value: "payoutAmount",
          text: "Award Amount",
          align: "right",
          sortable: false
        }
      ],
      options: {
        itemsPerPage: 3,
        page: 1,
        sortBy: ["effectiveDate"],
        sortDesc: [true]
      },
      loading: true
    };
  },
  methods: {
    fetchData() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      let filters = {
        payoutCategory: "RACE_AWARD"
      };

      if (this.selectedParticipant && this.selectedParticipant.id) {
        filters.participant = { id: this.selectedParticipant.id };
      }

      this.$api
        .post(
          "/api/payouts/search?size=" +
            itemsPerPage +
            "&page=" +
            (page - 1) +
            (sortBy && sortBy.length > 0
              ? "&sort=" + sortBy[0] + "," + ((sortDesc && sortDesc.length > 0) & sortDesc[0] ? "DESC" : "ASC")
              : ""),
          filters
        )
        .then(({ data }) => {
          this.items = data.content;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  computed: {
    ...mapGetters(["selectedParticipant", "selectedClient"])
  },
  created() {
    this.fetchData();
  },
  watch: {
    selectedParticipant() {
      this.fetchData();
    }
  }
};
</script>
