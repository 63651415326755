<template>
  <v-container class="pt-0 mt-0">
    <v-row class="mt-0 pt-0">
      <v-col class="mt-0 pt-0" cols="12" xs="12" sm="12" md="12" lg="8" xl="8">
        <v-alert border="top" colored-border color="primary" elevation="2" class="ma-0" height="100%">
          <ContentView :contentKey="'SALESPERSON_WELCOME'" />
        </v-alert>
      </v-col>
      <v-col class="mt-0 pt-0" cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
        <v-alert elevation="2" class="ma-0">
          <ContentView contentKey="SALESPERSON_BULLETIN" />
        </v-alert>
        <v-alert elevation="2" class="mt-6 mb-0">
          <ContentView contentKey="SALESPERSON_W9_FORM" />
        </v-alert>
      </v-col>
    </v-row>

    <Survey />

    <v-row>
      <v-col cols="12" xs="12" sm="12" md="12" lg="8" xl="8">
        <SalesPayoutCountAndAmountByDateChart :height="250" :key="key" />
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
        <AwardVehicleCard
          height="100%"
          class="salesperson-award-vehicle-card"
          selected
          v-if="reloadableCardAwardVehicle"
          :awardVehicle="reloadableCardAwardVehicle"
          :readOnly="true"
          :showTitle="false"
          :showDescription="false"
          lookupPrimaryBankAccount
        />
      </v-col>
    </v-row>

    <template>
      <v-row>
        <v-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
          <ParticipantEarnedWidget
            :to="{ name: 'salespersonEarnings', query: { tab: 0 } }"
            height="100%"
          ></ParticipantEarnedWidget>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
          <ParticipantPendingWidget
            :to="{ name: 'salespersonEarnings', query: { tab: 1 } }"
            height="100%"
          ></ParticipantPendingWidget>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
          <ParticipantExpiredWidget
            :to="{ name: 'salespersonEarnings', query: { tab: 2 } }"
            height="100%"
          ></ParticipantExpiredWidget>
        </v-col>
      </v-row>
    </template>

    <v-row>
      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <v-alert border="top" colored-border color="primary" elevation="2" class="ma-0" height="100%">
          <ContentView contentKey="PROGRAMS_ADMINISTRATION_INQUIRIES" />
        </v-alert>
      </v-col>
      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <v-alert border="top" colored-border color="primary" elevation="2" class="ma-0" height="100%">
          <ContentView contentKey="CUSTOMER_SERVICE_INQUIRIES" />
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SalesPayoutCountAndAmountByDateChart from "@/gapp-components/components/charts/SalesPayoutCountAndAmountByDateChart.vue";
import AwardVehicleCard from "@/gapp-components/components/common/awardVehicle/AwardVehicleCard.vue";
import ContentView from "@/gapp-components/components/common/content/ContentView.vue";
import Survey from "@/gapp-components/components/surveys/Survey.vue";
import { mapGetters } from "vuex";
import ParticipantEarnedWidget from "../widget/ParticipantEarnedWidget.vue";
import ParticipantExpiredWidget from "../widget/ParticipantExpiredWidget.vue";
import ParticipantPendingWidget from "../widget/ParticipantPendingWidget.vue";

export default {
  name: "SalespersonDashboard",
  metaInfo: {
    title: "Salesperson Dashboard"
  },
  components: {
    AwardVehicleCard,
    SalesPayoutCountAndAmountByDateChart,
    Survey,
    ContentView,
    ParticipantEarnedWidget,
    ParticipantPendingWidget,
    ParticipantExpiredWidget
  },
  data: () => ({
    key: 1,
    env: null,
    reloadableCardAwardVehicle: null,
    availableAwardVehicles: []
  }),
  created() {
    this.env = process.env;
  },
  mounted() {
    this.$store.dispatch("setBreadcrumb", []);
    this.$content.refreshContent();
    this.loading = true;
    this.getAvailableAwardVehicles()
      .then(() => {
        this.getReloadableCardAwardVehicle();
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    getAvailableAwardVehicles() {
      return this.$awardvehicle.getAvailableAwardVehicles(this.selectedParticipant).then(avs => {
        this.availableAwardVehicles = avs;
      });
    },

    getReloadableCardAwardVehicle() {
      for (let i = 0; i < this.availableAwardVehicles.length; i++) {
        let av = this.availableAwardVehicles[i];
        if (av.awardVehicleKey == "SALESPERSON_PAYOUTS") {
          this.reloadableCardAwardVehicle = av;
          return Promise.resolve(av);
        }
      }
      return Promise.resolve();
    },

    hasAccess(obj) {
      // If the user has a privilege, then return true
      if (obj.privileges && obj.privileges.length > 0) {
        let hasAtLeastOne = false;
        for (let i = 0; i < obj.privileges.length; i++) {
          let privilege = obj.privileges[i];
          if (this.$privilege.hasPrivilege(privilege)) {
            hasAtLeastOne = true;
          }
        }
        if (!hasAtLeastOne) {
          return false;
        }
      }

      if (obj.participantTypes && obj.participantTypes.length > 0) {
        if (!obj.participantTypes.includes(this.selectedParticipant.participantType.participantTypeKey)) {
          return false;
        }
      }

      if (obj.organizationTypeKeys && obj.organizationTypeKeys.length > 0) {
        if (
          !obj.organizationTypeKeys.includes(this.selectedParticipant.organization.organizationType.organizationTypeKey)
        ) {
          return false;
        }
      }

      if (obj.programs && obj.programs.length > 0) {
        if (!obj.programs.includes(this.selectedProgram.programKey)) {
          return false;
        }
      }

      if (obj.isApprovingAccountManager != null) {
        return obj.isApprovingAccountManager == this.showAccountManagerWidget;
      }
      return true;
    }
  },

  computed: {
    ...mapGetters(["selectedParticipant", "selectedProgram", "selectedClient"])
  }
};
</script>
