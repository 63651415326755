var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',{staticClass:"mb-5"},[(
        _vm.hasAccess({
          privileges: ['APPROVAL_CREATE']
        })
      )?_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"8","md":"6","lg":"6","xl":"6"}},[_c('v-btn',{attrs:{"color":"primary","to":{ name: 'approvalIncentive' }}},[_vm._v("Issue Incentive")]),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"info","to":{ name: 'approvalChargeback' }}},[_vm._v("Issue Chargeback")])],1):_vm._e(),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","xs":"12","sm":"4","md":"6","lg":"6","xl":"6"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticStyle:{"width":"115px","display":"inline-block"}},'div',attrs,false),on),[_c('FiscalYearField',{attrs:{"dense":"","hide-details":"","label":"Fiscal Year","hint":"Fiscal Year","persistent-hint":"","solo-inverted":"","dark":"","numberOfFutureYears":0,"numberOfPastYears":2,"client":_vm.selectedClient,"use-store":""},on:{"inputObj":function($event){_vm.selectedFiscalYear = $event}}})],1)]}}])},[_vm._v(" Fiscal Year ")])],1)],1),_c('v-alert',{attrs:{"border":"top","colored-border":"","color":"primary","elevation":"2"}},[_c('h3',{staticClass:"headline"},[_vm._v("Welcome "+_vm._s(_vm.selectedParticipant.fullName)+"!")]),_c('p',[_vm._v(" This dashboard is designed for Administrators to see all aspects of the Kawasaki Rewards program. Metrics and widgets on this dashboard are calculated by fiscal year which can be changed on the top right of the dashboard. ")])]),_c('Survey'),(
      _vm.hasAccess({
        participantTypes: ['100', '140', '150', '160']
      })
    )?[_c('v-row',[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"6","md":"3","lg":"3","xl":"3"}},[_c('AdminParticipantsExpandedWidget',{attrs:{"showActions":_vm.hasAccess({
              privileges: ['PARTICIPANT_READ']
            })}})],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"6","md":"3","lg":"3","xl":"3"}},[_c('AdminOrganizationsExpandedWidget',{attrs:{"showActions":_vm.hasAccess({
              privileges: ['ORGANIZATION_READ']
            })}})],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"6","md":"3","lg":"3","xl":"3"}},[_c('AdminPromotionsExpandedWidget',{attrs:{"showActions":_vm.hasAccess({
              privileges: ['PROMOTION_READ']
            })}})],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"6","md":"3","lg":"3","xl":"3"}},[_c('AdminApprovalExpandedWidget',{attrs:{"showActions":_vm.hasAccess({
              privileges: ['APPROVAL_READ']
            })}})],1)],1)]:_vm._e(),(_vm.hasAccess({ participantTypes: ['240'] }))?[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('PrincipalCompanyWidget')],1),_c('v-col',{attrs:{"cols":"8"}})],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }