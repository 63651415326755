<template>
  <v-card :loading="loading" v-bind="$attrs" v-on="$listeners">
    <v-card-title>
      <v-row>
        <v-col>
          <span class="headline">Vehicles</span>
        </v-col>
        <v-col class="text-right">
          <v-btn small color="primary" :to="{ name: 'profile', query: { tab: 'vehicles' } }">Manage</v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-subtitle>Currently registered VINs</v-card-subtitle>
    <v-card-text class="display-2 text-center">
      <v-progress-circular v-if="loading" :size="20" indeterminate color="primary"></v-progress-circular>
      <v-data-table v-else-if="items.length" :items="items" :headers="headers" hide-default-footer>
        <template v-slot:item.productKey="{ item }">
          {{ item.product.productKey || "N/A" }}
        </template>
        <template v-slot:item.year="{ item }">
          {{ item.product.customFieldValue2 || "N/A" }}
        </template>
      </v-data-table>
      <v-alert v-else type="info">No vehicles registered.</v-alert>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "RacerResultsWidget",
  data() {
    return {
      items: [],
      headers: [
        { value: "serialNumber", text: "VIN", sortable: false },
        { value: "year", text: "Year", sortable: false },
        { value: "productKey", text: "Model", sortable: false }
      ],
      loading: true
    };
  },
  methods: {
    fetchData() {
      this.loading = true;
      const { itemsPerPage, page } = { itemsPerPage: 3, page: 1 };

      let filters = {
        participant: { id: this.selectedParticipant.id }
      };

      if (this.selectedParticipant && this.selectedParticipant.id) {
        filters.participant = { id: this.selectedParticipant.id };
      }

      this.$api
        .post("/api/productRegistrations/search?size=" + itemsPerPage + "&page=" + (page - 1), filters)
        .then(({ data }) => {
          this.items = data.content.reduce((acc, item) => {
            const mappedVehicles = item.productRegistrationItems.map(({ id, product, serialNumber }) => ({
              product,
              serialNumber,
              id,
              registrationId: item.id
            }));
            return acc.concat(mappedVehicles);
          }, []);
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  computed: {
    ...mapGetters(["selectedParticipant", "selectedClient"])
  },
  created() {
    this.fetchData();
  },
  watch: {
    selectedParticipant() {
      this.fetchData();
    }
  }
};
</script>
