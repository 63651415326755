<template>
  <div>
    <AdminDashboard v-if="shouldAdminDashboardShow()" />
    <RacerDashboard v-else-if="shouldRacerDashboardShow()" />
    <SalespersonDashboard v-else />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AdminDashboard from "./admin/AdminDashboard.vue";
import RacerDashboard from "./racer/RacerDashboard.vue";
import SalespersonDashboard from "./salesperson/SalespersonDashboard.vue";

export default {
  name: "Dashboard",
  metaInfo: {
    title: "Dashboard"
  },
  components: { RacerDashboard, SalespersonDashboard, AdminDashboard },
  data: () => ({}),
  mounted() {},
  methods: {
    shouldAdminDashboardShow() {
      return parseInt(this.selectedParticipant.participantType.participantTypeKey) < 200;
    },
    shouldRacerDashboardShow() {
      return this.$kawasaki.isRacer();
    }
  },
  computed: {
    ...mapGetters(["selectedParticipant"])
  }
};
</script>
