<template>
  <v-container>
    <v-row class="mb-5">
      <v-col
        cols="12"
        xs="12"
        sm="8"
        md="6"
        lg="6"
        xl="6"
        v-if="
          hasAccess({
            privileges: ['APPROVAL_CREATE']
          })
        "
      >
        <v-btn color="primary" :to="{ name: 'approvalIncentive' }">Issue Incentive</v-btn>
        <v-btn color="info" :to="{ name: 'approvalChargeback' }" class="ml-2">Issue Chargeback</v-btn>
      </v-col>
      <v-col cols="12" xs="12" sm="4" md="6" lg="6" xl="6" class="text-right">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div style="width: 115px; display: inline-block" v-bind="attrs" v-on="on">
              <FiscalYearField
                dense
                hide-details
                label="Fiscal Year"
                hint="Fiscal Year"
                persistent-hint
                solo-inverted
                dark
                :numberOfFutureYears="0"
                :numberOfPastYears="2"
                :client="selectedClient"
                @inputObj="selectedFiscalYear = $event"
                use-store
              >
              </FiscalYearField>
            </div>
          </template>
          Fiscal Year
        </v-tooltip>
      </v-col>
    </v-row>
    <v-alert border="top" colored-border color="primary" elevation="2">
      <h3 class="headline">Welcome {{ selectedParticipant.fullName }}!</h3>

      <p>
        This dashboard is designed for Administrators to see all aspects of the Kawasaki Rewards program. Metrics and
        widgets on this dashboard are calculated by fiscal year which can be changed on the top right of the dashboard.
      </p>
    </v-alert>
    <Survey />
    <template
      v-if="
        hasAccess({
          participantTypes: ['100', '140', '150', '160']
        })
      "
    >
      <v-row>
        <v-col cols="12" xs="12" sm="6" md="3" lg="3" xl="3">
          <AdminParticipantsExpandedWidget
            :showActions="
              hasAccess({
                privileges: ['PARTICIPANT_READ']
              })
            "
          ></AdminParticipantsExpandedWidget>
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="3" lg="3" xl="3">
          <AdminOrganizationsExpandedWidget
            :showActions="
              hasAccess({
                privileges: ['ORGANIZATION_READ']
              })
            "
          ></AdminOrganizationsExpandedWidget>
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="3" lg="3" xl="3">
          <AdminPromotionsExpandedWidget
            :showActions="
              hasAccess({
                privileges: ['PROMOTION_READ']
              })
            "
          ></AdminPromotionsExpandedWidget>
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="3" lg="3" xl="3">
          <AdminApprovalExpandedWidget
            :showActions="
              hasAccess({
                privileges: ['APPROVAL_READ']
              })
            "
          ></AdminApprovalExpandedWidget>
        </v-col>
      </v-row>
    </template>

    <template v-if="hasAccess({ participantTypes: ['240'] })">
      <v-row>
        <v-col cols="4">
          <PrincipalCompanyWidget></PrincipalCompanyWidget>
        </v-col>
        <v-col cols="8"></v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import AdminApprovalExpandedWidget from "@/gapp-components/components/widgets/AdminApprovalExpandedWidget.vue";
import AdminPromotionsExpandedWidget from "@/gapp-components/components/widgets/AdminPromotionsExpandedWidget.vue";
import { mapGetters } from "vuex";
import FiscalYearField from "../../gapp-components/components/fields/FiscalYearField.vue";
import Survey from "../../gapp-components/components/surveys/Survey.vue";
import AdminOrganizationsExpandedWidget from "../../gapp-components/components/widgets/AdminOrganizationsExpandedWidget.vue";
import AdminParticipantsExpandedWidget from "../../gapp-components/components/widgets/AdminParticipantsExpandedWidget.vue";
import PrincipalCompanyWidget from "../widget/PrincipalCompanyWidget.vue";

import moment from "moment-timezone";

export default {
  name: "AdminDashboard",
  metaInfo: {
    title: "Admin Dashboard"
  },
  components: {
    FiscalYearField,
    AdminPromotionsExpandedWidget,
    PrincipalCompanyWidget,
    Survey,
    AdminApprovalExpandedWidget,
    AdminParticipantsExpandedWidget,
    AdminOrganizationsExpandedWidget
  },
  data: () => ({
    env: null,
    fiscalYearStart: null,
    fiscalYearEnd: null,
    availableAwardVehicles: [],
    showPreferredAwardVehicleDialog: true,
    selectedFiscalYear: {},
    showAccountManagerWidget: false
  }),
  created() {
    this.env = process.env;
  },
  mounted() {
    this.$store.dispatch("setBreadcrumb", []);
    let year = new moment().year();
    let fiscalYearStartMoment = moment(
      year + "-" + this.selectedClient.fiscalMonth + "-" + this.selectedClient.fiscalDay,
      "YYYY-MM-DD"
    );
    if (fiscalYearStartMoment.isAfter(new Date())) {
      fiscalYearStartMoment = moment(
        year - 1 + "-" + this.selectedClient.fiscalMonth + "-" + this.selectedClient.fiscalDay,
        "YYYY-MM-DD"
      );
    }

    this.fiscalYearStart = fiscalYearStartMoment.format("YYYY-MM-DDT00:00:00.000Z");
    this.fiscalYearEnd = fiscalYearStartMoment
      .add(1, "years")
      .add(-1, "days")
      .format("YYYY-MM-DDT00:00:00.000Z");

    this.$awardvehicle.getAvailableAwardVehicles(this.selectedParticipant).then(avs => {
      this.availableAwardVehicles = avs;
    });

    this.$api
      .post("/api/relatedParticipants/search", {
        relatedParticipant: { id: this.selectedParticipant.id }
      })
      .then(({ data }) => {
        if (data.content && data.content.length > 0) {
          let anyOrganizationIsZd = data.content.some(relatedParticipant => {
            if (relatedParticipant.organization.organizationType.organizationTypeKey == "ZD") {
              return true;
            }
          });
          if (anyOrganizationIsZd && this.selectedParticipant.participantType.participantTypeKey == "400") {
            this.showAccountManagerWidget = true;
          }
        }
      });
  },
  methods: {
    hasAccess(obj) {
      // If the user has a privilege, then return true
      if (obj.privileges && obj.privileges.length > 0) {
        let hasAtLeastOne = false;
        for (let i = 0; i < obj.privileges.length; i++) {
          let privilege = obj.privileges[i];
          if (this.$privilege.hasPrivilege(privilege)) {
            hasAtLeastOne = true;
          }
        }
        if (!hasAtLeastOne) {
          return false;
        }
      }

      if (obj.participantTypes && obj.participantTypes.length > 0) {
        if (!obj.participantTypes.includes(this.selectedParticipant.participantType.participantTypeKey)) {
          return false;
        }
      }

      if (obj.organizationTypeKeys && obj.organizationTypeKeys.length > 0) {
        if (
          !obj.organizationTypeKeys.includes(this.selectedParticipant.organization.organizationType.organizationTypeKey)
        ) {
          return false;
        }
      }

      if (obj.programs && obj.programs.length > 0) {
        if (!obj.programs.includes(this.selectedProgram.programKey)) {
          return false;
        }
      }

      if (obj.isApprovingAccountManager != null) {
        return obj.isApprovingAccountManager == this.showAccountManagerWidget;
      }
      return true;
    }
  },

  computed: {
    ...mapGetters(["selectedParticipant", "selectedProgram", "selectedClient"])
  }
};
</script>
